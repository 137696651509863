<template>
  <div class="userView">
    <ul>
      <li>
        <role-cell></role-cell>
      </li>
    </ul>
  </div>
</template>

<script>
import RoleCell from '@/views/userRole/roleCell'

export default {
  name: 'index',
  components: { RoleCell }
}
</script>

<style lang="scss" scoped>

.userView {
  width: 100%;
  height: 100%;


  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

}

</style>
