<template>
  <div class="roleCell">
    <img :src="photo">
    <div>
      <div>123</div>
      <div>123</div>
    </div>
<!--    <div class="content">123</div>-->
<!--    <el-select class="select"  v-model="value" placeholder="请选择">-->
<!--      <el-option-->
<!--          v-for="item in options"-->
<!--          :key="item.value"-->
<!--          :label="item.label"-->
<!--          :value="item.value"-->
<!--      >-->
<!--      </el-option>-->
<!--    </el-select>-->
  </div>
</template>

<script>

let userPhoto = require('../../../static/iconImage/yonghu.png')

export default {
  name: 'roleCell',
  data() {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  props: {
    userData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    photo: function() {
      return userPhoto
      if (this.userData.photo == '') {
        return userPhoto
      }
      return process.env.VUE_APP_BASEURL + '/api/image/get?id=' + this.userData.photo
    }
  }
}
</script>

<style lang="scss" scoped>

.roleCell {
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
    margin-left: 10px;
  }



  .content {
    margin-left: 10px;
    width: calc(100% - 230px);
    word-break: break-all;
    line-height: 30px;
    font-size: 25px;
  }

  .select {
    margin-left: 10px;
    width: 150px;
    margin-right: 10px;
  }

}

</style>
